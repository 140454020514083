import { Box, Theme, Tooltip, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, TextDisplay, DateDisplay, NumericDisplay } from '@ngt/forms';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import FormDates from '../../components/form/FormDates';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;
const opmsUrl = global.OPMS_URL as string;

interface IClinicalAssessmentFormProps {
}


const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    titleContainer: {
        padding: theme.spacing(2, 2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    protocolDeviations: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const conditionSubscription = { value: true };

const ClinicalAssessmentForm: FunctionComponent<IClinicalAssessmentFormProps> = () => {
    const { classes } = useStyles();

    return (
        <>
            <ContextMedicalReviewFormBreadcrumbs />
            <div className={classes.patientInformation}>
                <ContextPatientInformation />
            </div>
            <ContextMedicalReviewForm
                disableEntityLowerCase
                disableEntityTitleCase
            >
                <Box className={classes.titleContainer}>
                    <Typography variant="h1">
                        <ContextFormTitle />
                    </Typography>
                    <FormDates />
                </Box>

                <GroupedField
                    name="physicalExamDate"
                    component={DateDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="ecogPerformanceStatus"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="height"
                    component={NumericDisplay}
                    suffix=" cm"
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="weight"
                    component={NumericDisplay}
                    suffix=" kg"
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="ethnicity"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="breastMriDate"
                    component={DateDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="menopausalStatus"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                    label={
                        <Tooltip
                            title={
                                <>
                                    Patients will be clinically defined as pre-or peri-menopausal unless one of the following criteria is met for the definition of post-menopausal:
                                    <ol type="i">
                                        <li>Patient with intact uterus and ovaries and age &gt;55 years and amenorrhea for &gt;1 year;</li>
                                        <li>Surgical bilateral oophorectomy;</li>
                                        <li>Postmenopausal oestradiol/luteinizing hormone (LH)/follicle stimulating hormone (FSH) levels.</li>
                                    </ol>
                                </>
                            }
                        >
                            <Box>
                                Menopausal status 
                            </Box>
                        </Tooltip>
                    }
                />

                <GroupedField
                    name="patientPregnant"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="evidenceOfDistantMetastasis"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />

                <GroupedField
                    name="patientLifeExpectancy"
                    component={TextDisplay}
                    GroupStyleProps={groupStyleProps}
                />
                
            </ContextMedicalReviewForm>
        </>
    );
};


export default ClinicalAssessmentForm;
